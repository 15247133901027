import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function SpinnerAllColorPreview(props) {
  const {
    SpinnerBaseColorPinkHalf,
    SpinnerBaseColorPinkFourth,
    SpinnerBaseColorPinkEighth,
    SpinnerBaseColorCyanHalf,
    SpinnerBaseColorCyanFourth,
    SpinnerBaseColorCyanEighth,
    SpinnerBaseColorAmberHalf,
    SpinnerBaseColorAmberFourth,
    SpinnerBaseColorAmberEighth,
  } = props

  return (
    <div className="flex flex-col items-center justify-center w-full gap-10 rounded-md">
      {/*<!-- Component: All colored circle spinners --> */}
      {/*<!-- 1/2 --> */}
      <div className="flex gap-10">
        {/*<!-- Pink --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorPinkHalf}
          componentName="SpinnerBaseColorPinkHalf"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-05a desc-05a"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-05a">Icon title</title>
            <desc id="desc-05a">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
              className="stroke-pink-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>

        {/*<!-- Cyan --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorCyanHalf}
          componentName="SpinnerBaseColorCyanHalf"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-05b desc-05b"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-05b">Icon title</title>
            <desc id="desc-05b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
              className="stroke-cyan-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>

        {/*<!-- Amber --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorAmberHalf}
          componentName="SpinnerBaseColorAmberHalf"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-05 desc-05"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-05">Icon title</title>
            <desc id="desc-05">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
              className="stroke-amber-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
      </div>

      {/*<!-- 1/4 --> */}
      <div className="flex gap-10">
        {/*<!-- Pink --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorPinkFourth}
          componentName="SpinnerBaseColorPinkFourth"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-06a desc-06a"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-06a">Icon title</title>
            <desc id="desc-06a">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-pink-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>

        {/*<!-- Cyan --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorCyanFourth}
          componentName="SpinnerBaseColorCyanFourth"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-06b desc-06b"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-06b">Icon title</title>
            <desc id="desc-06b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-cyan-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>

        {/*<!-- Amber --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorAmberFourth}
          componentName="SpinnerBaseColorAmberFourth"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-06c desc-06c"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-06c">Icon title</title>
            <desc id="desc-06c">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-amber-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
      </div>

      {/*<!-- 1/8 --> */}
      <div className="flex gap-10">
        {/*<!-- Pink --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorPinkEighth}
          componentName="SpinnerBaseColorPinkEighth"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-07a desc-07a"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-07a">Icon title</title>
            <desc id="desc-07a">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
              className="stroke-pink-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>

        {/*<!-- Cyan --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorCyanEighth}
          componentName="SpinnerBaseColorCyanEighth"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-07b desc-07b"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-07b">Icon title</title>
            <desc id="desc-07b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
              className="stroke-cyan-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>

        {/*<!-- Amber --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseColorAmberEighth}
          componentName="SpinnerBaseColorAmberEighth"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-07c desc-07c"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-07c">Icon title</title>
            <desc id="desc-07c">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
              className="stroke-amber-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
      </div>
      {/*<!-- End All colored circle spinners --> */}
    </div>
  )
}
