import React from "react"

function SpinnerHeroComponent(props) {
  return (
    <>
      {/*<!-- xl size --> */}
      <div className="flex gap-10">
        {/*  <!-- 1/2 --> */}
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title-04a desc-04a"
          className="w-10 h-10 animate animate-spin"
        >
          <title id="title-04a">Icon title</title>
          <desc id="desc-04a">Some desc</desc>
          <circle
            cx="12"
            cy="12"
            r="10"
            className="stroke-slate-200"
            strokeWidth="4"
          />
          <path
            d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
            className="stroke-emerald-500"
            strokeWidth="4"
          />
        </svg>

        {/*  <!-- 1/4 --> */}
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title-04b desc-04b"
          className="w-10 h-10 animate animate-spin"
        >
          <title id="title-04b">Icon title</title>
          <desc id="desc-04b">Some desc</desc>
          <circle
            cx="12"
            cy="12"
            r="10"
            className="stroke-slate-200"
            strokeWidth="4"
          />
          <path
            d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
            className="stroke-emerald-500"
            strokeWidth="4"
          />
        </svg>

        {/*  <!-- 1/8 --> */}
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="title-04c desc-04c"
          className="w-10 h-10 animate animate-spin"
        >
          <title id="title-04c">Icon title</title>
          <desc id="desc-04c">Some desc</desc>
          <circle
            cx="12"
            cy="12"
            r="10"
            className="stroke-slate-200"
            strokeWidth="4"
          />
          <path
            d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
            className="stroke-emerald-500"
            strokeWidth="4"
          />
        </svg>
      </div>
      {/*<!-- End xl size --> */}
    </>
  )
}

export default SpinnerHeroComponent
