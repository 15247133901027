import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function SpinnerAllBasicPreview(props) {
  const {
    SpinnerXlBasicHalf,
    SpinnerXlBasicOneFourth,
    SpinnerXlBasicOneEighth,
    SpinnerLgBasicHalf,
    SpinnerLgBasicOneFourth,
    SpinnerLgBasicOneEighth,
    SpinnerBaseBasicHalf,
    SpinnerBaseBasicOneFourth,
    SpinnerBaseBasicOneEighth,
    SpinnerSmBasicHalf,
    SpinnerSmBasicOneFourth,
    SpinnerSmBasicOneEighth,
  } = props

  return (
    <div className="flex flex-col items-center justify-center w-full gap-10 rounded-md">
      {/*<!-- Component: All basic circle spinners --> */}
      {/*<!-- xl size --> */}
      <div className="flex gap-10">
        <CopyComponent
          copyToClipboardCode={SpinnerXlBasicHalf}
          componentName="SpinnerXlBasicHalf"
        >
          {/*  <!-- 1/2 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-04a desc-04a"
            className="w-10 h-10 animate animate-spin"
          >
            <title id="title-04a">Icon title</title>
            <desc id="desc-04a">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerXlBasicOneFourth}
          componentName="SpinnerXlBasicOneFourth"
        >
          {/*  <!-- 1/4 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-04b desc-04b"
            className="w-10 h-10 animate animate-spin"
          >
            <title id="title-04b">Icon title</title>
            <desc id="desc-04b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerXlBasicOneEighth}
          componentName="SpinnerXlBasicOneEighth"
        >
          {/*  <!-- 1/8 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-04c desc-04c"
            className="w-10 h-10 animate animate-spin"
          >
            <title id="title-04c">Icon title</title>
            <desc id="desc-04c">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
      </div>
      {/*<!-- End xl size --> */}

      {/*<!-- lg size --> */}
      <div className="flex gap-10">
        <CopyComponent
          copyToClipboardCode={SpinnerLgBasicHalf}
          componentName="SpinnerLgBasicHalf"
        >
          {/*  <!-- 1/2 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-03a desc-03a"
            className="w-8 h-8 animate animate-spin"
          >
            <title id="title-03a">Icon title</title>
            <desc id="desc-03a">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerLgBasicOneFourth}
          componentName="SpinnerLgBasicOneFourth"
        >
          {/*  <!-- 1/4 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-03b desc-03b"
            className="w-8 h-8 animate animate-spin"
          >
            <title id="title-03b">Icon title</title>
            <desc id="desc-03b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerLgBasicOneEighth}
          componentName="SpinnerLgBasicOneEighth"
        >
          {/*  <!-- 1/8 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-03c desc-03c"
            className="w-8 h-8 animate animate-spin"
          >
            <title id="title-03c">Icon title</title>
            <desc id="desc-03c">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
      </div>
      {/*<!-- End lg size --> */}

      {/*<!-- base size --> */}
      <div className="flex gap-10">
        <CopyComponent
          copyToClipboardCode={SpinnerBaseBasicHalf}
          componentName="SpinnerBaseBasicHalf"
        >
          {/*  <!-- 1/2 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-02a desc-02a"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-02a">Icon title</title>
            <desc id="desc-02a">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerBaseBasicOneFourth}
          componentName="SpinnerBaseBasicOneFourth"
        >
          {/*  <!-- 1/4 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-02b desc-02b"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-02b">Icon title</title>
            <desc id="desc-02b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerBaseBasicOneEighth}
          componentName="SpinnerBaseBasicOneEighth"
        >
          {/*  <!-- 1/8 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-02c desc-02c"
            className="w-6 h-6 animate animate-spin"
          >
            <title id="title-02c">Icon title</title>
            <desc id="desc-02c">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
      </div>
      {/*<!-- End base size --> */}

      {/*<!-- sm size --> */}
      <div className="flex gap-10">
        <CopyComponent
          copyToClipboardCode={SpinnerSmBasicHalf}
          componentName="SpinnerSmBasicHalf"
        >
          {/*  <!-- 1/2 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-01a desc-01a"
            className="w-4 h-4 animate animate-spin"
          >
            <title id="title-01a">Icon title</title>
            <desc id="desc-01a">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerSmBasicOneFourth}
          componentName="SpinnerSmBasicOneFourth"
        >
          {/*  <!-- 1/4 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-01b desc-01b"
            className="w-4 h-4 animate animate-spin"
          >
            <title id="title-01b">Icon title</title>
            <desc id="desc-01b">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
        <CopyComponent
          copyToClipboardCode={SpinnerSmBasicOneEighth}
          componentName="SpinnerSmBasicOneEighth"
        >
          {/*  <!-- 1/8 --> */}
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-01c desc-01c"
            className="w-4 h-4 animate animate-spin"
          >
            <title id="title-01c">Icon title</title>
            <desc id="desc-01c">Some desc</desc>
            <circle
              cx="12"
              cy="12"
              r="10"
              className="stroke-slate-200"
              strokeWidth="4"
            />
            <path
              d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893"
              className="stroke-emerald-500"
              strokeWidth="4"
            />
          </svg>
        </CopyComponent>
      </div>
      {/*<!-- End sm size --> */}
      {/*<!-- End All basic circle spinners --> */}
    </div>
  )
}
