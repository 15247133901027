import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function SpinnerAllSquarePreview(props) {
  const {
    SpinnerBaseSquareHorizontal,
    SpinnerSmSquareHorizontal,
    SpinnerBaseSquareVertical,
    SpinnerSmSquareVertical,
  } = props

  return (
    <div className="flex flex-col items-center justify-center w-full gap-10 p-10 rounded-md ">
      {/*<!-- Component: All square spinners --> */}
      <div className="flex gap-10">
        {/*  <!-- Square horizontal spinner component --> */}
        {/*  <!-- base size --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseSquareHorizontal}
          componentName="SpinnerBaseSquareHorizontal"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-08a desc-08a"
            className="w-6 h-6"
          >
            <title id="title-08a">Icon title</title>
            <desc id="desc-08a">Some desc</desc>
            <path
              d="M7 8H3V16H7V8Z"
              className="animate animate-bounce fill-emerald-500 "
            />
            <path
              d="M14 8H10V16H14V8Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.2s]"
            />
            <path
              d="M21 8H17V16H21V8Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.4s]"
            />
          </svg>
        </CopyComponent>

        {/*  <!-- sm size --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerSmSquareHorizontal}
          componentName="SpinnerSmSquareHorizontal"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-08b desc-08b"
            className="w-6 h-6"
          >
            <title id="title-08b">Icon title</title>
            <desc id="desc-08b">Some desc</desc>
            <path
              d="M7 10H3V14H7V10Z"
              className="animate animate-bounce fill-emerald-500 "
            />
            <path
              d="M14 10H10V14H14V10Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.2s]"
            />
            <path
              d="M21 10H17V14H21V10Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.4s]"
            />
          </svg>
        </CopyComponent>
        {/*  <!-- End Square horizontal spinner component --> */}
      </div>
      <div className="flex gap-10">
        {/*  <!-- Square vertical spinner component --> */}
        {/*  <!-- base size --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerBaseSquareVertical}
          componentName="SpinnerBaseSquareVertical"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-08c desc-08c"
            className="w-6 h-6 rotate-90"
          >
            <title id="title-08c">Icon title</title>
            <desc id="desc-08c">Some desc</desc>
            <path
              d="M7 8H3V16H7V8Z"
              className="animate animate-bounce fill-emerald-500 "
            />
            <path
              d="M14 8H10V16H14V8Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.2s]"
            />
            <path
              d="M21 8H17V16H21V8Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.4s]"
            />
          </svg>
        </CopyComponent>

        {/*  <!-- sm size --> */}
        <CopyComponent
          copyToClipboardCode={SpinnerSmSquareVertical}
          componentName="SpinnerSmSquareVertical"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="title-08d desc-08d"
            className="w-6 h-6 rotate-90"
          >
            <title id="title-08d">Icon title</title>
            <desc id="desc-08d">Some desc</desc>
            <path
              d="M7 10H3V14H7V10Z"
              className="animate animate-bounce fill-emerald-500 "
            />
            <path
              d="M14 10H10V14H14V10Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.2s]"
            />
            <path
              d="M21 10H17V14H21V10Z"
              className="animate animate-bounce fill-emerald-500 [animation-delay:.4s]"
            />
          </svg>
        </CopyComponent>
        {/*  <!-- End Square vertical spinner component --> */}
      </div>
      {/*<!-- End All square spinners --> */}
    </div>
  )
}
