import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/spinners/spinners.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
// Preview
import SpinnerHeroComponent from "../../../library/components/spinner/react/_preview/SpinnerHeroComponent"

import SpinnerAllBasicPreview from "../../../library/components/spinner/react/_preview/basic"
import SpinnerAllColorPreview from "../../../library/components/spinner/react/_preview/color"
import SpinnerAllSquarePreview from "../../../library/components/spinner/react/_preview/square"

// Basic - xl
const SpinnerXlBasicHalfJsx = require("!!raw-loader!../../../library/components/spinner/react/xl/basic-half.jsx")
const SpinnerXlBasicHalfHTML = require("!!raw-loader!../../../library/components/spinner/html/xl/basic-half.html")

const SpinnerXlBasicOneFourthJsx = require("!!raw-loader!../../../library/components/spinner/react/xl/basic-one_fourth.jsx")
const SpinnerXlBasicOneFourthHTML = require("!!raw-loader!../../../library/components/spinner/html/xl/basic-one_fourth.html")

const SpinnerXlBasicOneEighthJsx = require("!!raw-loader!../../../library/components/spinner/react/xl/basic-one_eighth.jsx")
const SpinnerXlBasicOneEighthHTML = require("!!raw-loader!../../../library/components/spinner/html/xl/basic-one_eighth.html")

// Basic - lg
const SpinnerLgBasicHalfJsx = require("!!raw-loader!../../../library/components/spinner/react/lg/basic-half.jsx")
const SpinnerLgBasicHalfHTML = require("!!raw-loader!../../../library/components/spinner/html/lg/basic-half.html")

const SpinnerLgBasicOneFourthJsx = require("!!raw-loader!../../../library/components/spinner/react/lg/basic-one_fourth.jsx")
const SpinnerLgBasicOneFourthHTML = require("!!raw-loader!../../../library/components/spinner/html/lg/basic-one_fourth.html")

const SpinnerLgBasicOneEighthJsx = require("!!raw-loader!../../../library/components/spinner/react/lg/basic-one_eighth.jsx")
const SpinnerLgBasicOneEighthHTML = require("!!raw-loader!../../../library/components/spinner/html/lg/basic-one_eighth.html")

// Basic - Base
const SpinnerBaseBasicHalfJsx = require("!!raw-loader!../../../library/components/spinner/react/base/basic-half.jsx")
const SpinnerBaseBasicHalfHTML = require("!!raw-loader!../../../library/components/spinner/html/base/basic-half.html")

const SpinnerBaseBasicOneFourthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/basic-one_fourth.jsx")
const SpinnerBaseBasicOneFourthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/basic-one_fourth.html")

const SpinnerBaseBasicOneEighthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/basic-one_eighth.jsx")
const SpinnerBaseBasicOneEighthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/basic-one_eighth.html")

// Basic - sm
const SpinnerSmBasicHalfJsx = require("!!raw-loader!../../../library/components/spinner/react/sm/basic-half.jsx")
const SpinnerSmBasicHalfHTML = require("!!raw-loader!../../../library/components/spinner/html/sm/basic-half.html")

const SpinnerSmBasicOneFourthJsx = require("!!raw-loader!../../../library/components/spinner/react/sm/basic-one_fourth.jsx")
const SpinnerSmBasicOneFourthHTML = require("!!raw-loader!../../../library/components/spinner/html/sm/basic-one_fourth.html")

const SpinnerSmBasicOneEighthJsx = require("!!raw-loader!../../../library/components/spinner/react/sm/basic-one_eighth.jsx")
const SpinnerSmBasicOneEighthHTML = require("!!raw-loader!../../../library/components/spinner/html/sm/basic-one_eighth.html")

// Coloured
const SpinnerBaseColorPinkHalfJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-pink_half.jsx")
const SpinnerBaseColorPinkHalfHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-pink_half.html")

const SpinnerBaseColorPinkFourthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-pink_fourth.jsx")
const SpinnerBaseColorPinkFourthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-pink_fourth.html")

const SpinnerBaseColorPinkEighthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-pink_eighth.jsx")
const SpinnerBaseColorPinkEighthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-pink_eighth.html")

const SpinnerBaseColorCyanHalfJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-cyan_half.jsx")
const SpinnerBaseColorCyanHalfHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-cyan_half.html")

const SpinnerBaseColorCyanFourthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-cyan_fourth.jsx")
const SpinnerBaseColorCyanFourthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-cyan_fourth.html")

const SpinnerBaseColorCyanEighthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-cyan_eighth.jsx")
const SpinnerBaseColorCyanEighthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-cyan_eighth.html")

const SpinnerBaseColorAmberHalfJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-amber_half.jsx")
const SpinnerBaseColorAmberHalfHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-amber_half.html")

const SpinnerBaseColorAmberFourthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-amber_fourth.jsx")
const SpinnerBaseColorAmberFourthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-amber_fourth.html")

const SpinnerBaseColorAmberEighthJsx = require("!!raw-loader!../../../library/components/spinner/react/base/color-amber_eighth.jsx")
const SpinnerBaseColorAmberEighthHTML = require("!!raw-loader!../../../library/components/spinner/html/base/color-amber_eighth.html")

// Squared

const SpinnerBaseSquareHorizontalJsx = require("!!raw-loader!../../../library/components/spinner/react/base/square-horizontal.jsx")
const SpinnerBaseSquareHorizontalHTML = require("!!raw-loader!../../../library/components/spinner/html/base/square-horizontal.html")

const SpinnerSmSquareHorizontalJsx = require("!!raw-loader!../../../library/components/spinner/react/sm/square-horizontal.jsx")
const SpinnerSmSquareHorizontalHTML = require("!!raw-loader!../../../library/components/spinner/html/sm/square-horizontal.html")

const SpinnerBaseSquareVerticalJsx = require("!!raw-loader!../../../library/components/spinner/react/base/square-vertical.jsx")
const SpinnerBaseSquareVerticalHTML = require("!!raw-loader!../../../library/components/spinner/html/base/square-vertical.html")

const SpinnerSmSquareVerticalJsx = require("!!raw-loader!../../../library/components/spinner/react/sm/square-vertical.jsx")
const SpinnerSmSquareVerticalHTML = require("!!raw-loader!../../../library/components/spinner/html/sm/square-vertical.html")

export default function SpinnersPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "SpinnerAllBasic",
      title: "Basic Spinners",
      active_tab: 1,
    },
    {
      component_name: "SpinnerAllColor",
      title: "Colored",
      active_tab: 1,
    },
    {
      component_name: "SpinnerAllSquare",
      title: "Squared",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Spinners - WindUI Component Library"
        ogtitle="Tailwind CSS Spinners - WindUI Component Library"
        description="Spinner components indicate the loading state of a page or a page section. Built with Tailwind CSS by WindUI."
        ogdescription="Spinner components indicate the loading state of a page or a page section. Built with Tailwind CSS by WindUI."
        url="components/spinners/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Spinners, Spinners, Spinner Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Spinners</h1>
          <p>Indicates the loading state of a page or a page section.</p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <SpinnerHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Basic Spinners */}
          <h3 id="SpinnerAllBasic">Basic Spinners</h3>

          <PreviewBlock
            id="SpinnerAllBasic"
            HtmlComponent={SpinnerXlBasicHalfHTML.default}
            JsxComponent={SpinnerXlBasicHalfJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <SpinnerAllBasicPreview
                SpinnerXlBasicHalf={
                  activeTabs[0].active_tab === 1
                    ? SpinnerXlBasicHalfHTML.default
                    : SpinnerXlBasicHalfJsx.default
                }
                SpinnerXlBasicOneFourth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerXlBasicOneFourthHTML.default
                    : SpinnerXlBasicOneFourthJsx.default
                }
                SpinnerXlBasicOneEighth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerXlBasicOneEighthHTML.default
                    : SpinnerXlBasicOneEighthJsx.default
                }
                SpinnerLgBasicHalf={
                  activeTabs[0].active_tab === 1
                    ? SpinnerLgBasicHalfHTML.default
                    : SpinnerLgBasicHalfJsx.default
                }
                SpinnerLgBasicOneFourth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerLgBasicOneFourthHTML.default
                    : SpinnerLgBasicOneFourthJsx.default
                }
                SpinnerLgBasicOneEighth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerLgBasicOneEighthHTML.default
                    : SpinnerLgBasicOneEighthJsx.default
                }
                SpinnerBaseBasicHalf={
                  activeTabs[0].active_tab === 1
                    ? SpinnerBaseBasicHalfHTML.default
                    : SpinnerBaseBasicHalfJsx.default
                }
                SpinnerBaseBasicOneFourth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerBaseBasicOneFourthHTML.default
                    : SpinnerBaseBasicOneFourthJsx.default
                }
                SpinnerBaseBasicOneEighth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerBaseBasicOneEighthHTML.default
                    : SpinnerBaseBasicOneEighthJsx.default
                }
                SpinnerSmBasicHalf={
                  activeTabs[0].active_tab === 1
                    ? SpinnerSmBasicHalfHTML.default
                    : SpinnerSmBasicHalfJsx.default
                }
                SpinnerSmBasicOneFourth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerSmBasicOneFourthHTML.default
                    : SpinnerSmBasicOneFourthJsx.default
                }
                SpinnerSmBasicOneEighth={
                  activeTabs[0].active_tab === 1
                    ? SpinnerSmBasicOneEighthHTML.default
                    : SpinnerSmBasicOneEighthJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/*Colored Spinners*/}
          <h3 id="SpinnerAllColor">Colored Spinners</h3>

          <PreviewBlock
            id="SpinnerAllColor"
            HtmlComponent={SpinnerBaseColorPinkHalfHTML.default}
            JsxComponent={SpinnerBaseColorPinkHalfJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <SpinnerAllColorPreview
                SpinnerBaseColorPinkHalf={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorPinkHalfHTML.default
                    : SpinnerBaseColorPinkHalfJsx.default
                }
                SpinnerBaseColorPinkFourth={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorPinkFourthHTML.default
                    : SpinnerBaseColorPinkFourthJsx.default
                }
                SpinnerBaseColorPinkEighth={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorPinkEighthHTML.default
                    : SpinnerBaseColorPinkEighthJsx.default
                }
                SpinnerBaseColorCyanHalf={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorCyanHalfHTML.default
                    : SpinnerBaseColorCyanHalfJsx.default
                }
                SpinnerBaseColorCyanFourth={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorCyanFourthHTML.default
                    : SpinnerBaseColorCyanFourthJsx.default
                }
                SpinnerBaseColorCyanEighth={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorCyanEighthHTML.default
                    : SpinnerBaseColorCyanEighthJsx.default
                }
                SpinnerBaseColorAmberHalf={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorAmberHalfHTML.default
                    : SpinnerBaseColorAmberHalfJsx.default
                }
                SpinnerBaseColorAmberFourth={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorAmberFourthHTML.default
                    : SpinnerBaseColorAmberFourthJsx.default
                }
                SpinnerBaseColorAmberEighth={
                  activeTabs[1].active_tab === 1
                    ? SpinnerBaseColorAmberEighthHTML.default
                    : SpinnerBaseColorAmberEighthJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/*Squared Spinners*/}
          <h3 id="SpinnerAllSquare">Squared Spinners</h3>

          <PreviewBlock
            id="SpinnerAllSquare"
            HtmlComponent={SpinnerBaseSquareHorizontalHTML.default}
            JsxComponent={SpinnerBaseSquareHorizontalJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <SpinnerAllSquarePreview
                SpinnerBaseSquareHorizontal={
                  activeTabs[2].active_tab === 1
                    ? SpinnerBaseSquareHorizontalHTML.default
                    : SpinnerBaseSquareHorizontalJsx.default
                }
                SpinnerSmSquareHorizontal={
                  activeTabs[2].active_tab === 1
                    ? SpinnerSmSquareHorizontalHTML.default
                    : SpinnerSmSquareHorizontalJsx.default
                }
                SpinnerBaseSquareVertical={
                  activeTabs[2].active_tab === 1
                    ? SpinnerBaseSquareVerticalHTML.default
                    : SpinnerBaseSquareVerticalJsx.default
                }
                SpinnerSmSquareVertical={
                  activeTabs[2].active_tab === 1
                    ? SpinnerSmSquareVerticalHTML.default
                    : SpinnerSmSquareVerticalJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use spinners to inform users about the status of ongoing processes,
            such as loading of the page, submitting a form, or saving updates.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Spinners come in 2 styles (circle and square spinners) and 4 sizes:
          </p>
          <ul>
            <li>
              <strong>Small: </strong> 1rem (16px) width and height.
            </li>
            <li>
              <strong>Base: </strong> 1.5rem (24px) width and height.
            </li>
            <li>
              <strong>Large: </strong> 2rem (32px) width and height.
            </li>
            <li>
              <strong>Extra large: </strong> 2.5rem (40px) width and height.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            It’s almost expected that web apps will need some time to process
            their response to user actions. Those delays can be tied to
            interactions like submitting forms, changing routes, loading content
            from an API, and uploading files, to name a few.
          </p>
          <p>
            Use these roles to the spinners container to improve accessibility:
          </p>
          <ul>
            <li>
              <code>aria-live</code> : This ARIA attribute tells screen readers
              and other assistive tech, that part of the app contains dynamic
              content. The aria-live attribute takes three values:{" "}
              <strong>off</strong>, <strong>polite</strong>, and{" "}
              <strong> assertive / rude</strong>. Generally speaking, polite is
              a good default.
            </li>
            <li>
              <code>aria-busy</code> : Once a container is aria-live, we can use
              aria-busy to tell the assistive tech that the container is getting
              new content. When the container is not refreshing, aria-busy will
              be <strong>false</strong>, and when it is waiting for new content,
              it’ll be <strong>true.</strong>
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
